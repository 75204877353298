@charset 'utf-8';
/*===========================================================================
	GLOBAL
============================================================================*/
body .content > .main {
}

/*===========================================================================
	COUPON CONTENTS
============================================================================*/
.coupon-information {
}
.coupon-information > * {
  z-index: 1;
}
.coupon-information > .inner-section.type1 {
  z-index: 30;
  position: fixed;
  top: 0;
  overflow: hidden;
  max-width: 420px;
  width: 100%;
  background-color: #fff;
  height: 50px;
  border-bottom: #dddddd 1px solid;
}
.coupon-information > .inner-section.type1 > .head-line-area {
  box-sizing: border-box;
  margin: 0;
  padding: 10px 80px 11px 40px;
}
.coupon-information > .inner-section.type1 > .head-line-area > ul {
}
.coupon-information > .inner-section.type1 > .head-line-area > ul > li {
}
.coupon-information > .inner-section.type1 > .head-line-area > .back {
  position: absolute;
  top: 10px;
  left: 8px;
  height: 10px;
  margin: 0;
  padding: 0;
}
.coupon-information
  > .inner-section.type1
  > .head-line-area
  > .back
  > .img-bul {
  padding: 7px;
  width: 15px;
  height: 15px;
  background-size: 15px;
  background-image: url(./img/bul_back.svg);
  background-repeat: no-repeat;
  background-position: 50%;
}
.coupon-information > .inner-section.type1 > .head-line-area > .name {
  color: #555555;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: -0.8px;
  font-weight: 500;
}
.coupon-information > .inner-section.type1 > .head-line-area > .view {
  position: absolute;
  top: 10px;
  right: 15px;
  padding-left: 20px;
  color: #999999;
  font-size: 13px;
  line-height: 29px;
  letter-spacing: -0.65px;
  background-image: url(./img/bul_view.svg);
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 20px;
  max-width: 60px;
}
.coupon-information > .inner-section.type2 {
  height: 51px;
}

/* ._coupon.slide-container {
  height: 84px;
} */
._coupon .card-template.coupon {
  margin: 0;
}

._coupon .card-template.coupon2 {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

._coupon-signboard .coupon .half-circle {
  background-color: #eeeeee;
}
._coupon-signboard .coupon .half-circle.left {
  border-right-color: #eeeeee;
}
._coupon-signboard .coupon .half-circle.right {
  border-left-color: #eeeeee;
}

._coupon.simplify .card-template.coupon {
  height: auto;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
._coupon.simplify .card-template.coupon > .inner-inner-section1 {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: auto;
  height: auto;
  padding: 15px;
  justify-content: center;
  border: none;
  border-left: 1px solid #eee;
}
._coupon.simplify .card-template.coupon > .inner-inner-section1 > span {
  margin: 0;
  position: relative;
  top: 0;
}
._coupon.simplify .card-template.coupon > .inner-inner-section1 > img {
  width: 18px;
  height: 18px;
  margin-top: 2px;
  filter: invert(34%) sepia(85%) saturate(688%) hue-rotate(166deg)
    brightness(99%) contrast(88%);
}
._coupon.simplify .card-template.coupon > .inner-inner-section2 {
  position: relative;
  margin: 0;
  padding: 15px;
  border: none;
}
._coupon.simplify .card-template.coupon > .inner-inner-section2 > .period {
  position: relative;
  bottom: 0;
  margin-top: 4px;
}
._coupon.simplify
  .card-template.coupon
  > .inner-inner-section2
  > .period
  > .blue-text {
  margin-left: 4px;
  color: #1c79bc;
}

._coupon-signboard.add-img > .card-template.coupon {
  float: left;
  width: calc(50% - 5px);
  height: auto;
  overflow: hidden;
  border-radius: 6px;
}
._coupon-signboard.add-img > .card-template.coupon > .inner-inner-section1 {
  position: relative;
  width: 100%;
  height: 112px;
  border: none;
}
._coupon-signboard.add-img
  > .card-template.coupon
  > .inner-inner-section1
  > .discount {
  position: relative;
}
._coupon-signboard.add-img
  > .card-template.coupon
  > .inner-inner-section2
  > span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
._coupon-signboard.add-img > .card-template.coupon > .inner-inner-section2 {
  position: relative;
  margin: 0;
  padding: 15px;
  height: auto;
}
._coupon-signboard.add-img
  > .card-template.coupon
  > .inner-inner-section2
  > .period {
  position: relative;
  bottom: 0;
  font-size: 11px;
}
._coupon-signboard.add-img > .card-template.coupon > .coupon-permalink {
  border-top: 1px dashed #aaa;
}
._coupon-signboard.add-img > .card-template.coupon > .coupon-permalink > a {
  display: block;
  line-height: 30px;
  color: #1b79bc;
  text-align: center;
  font-size: 14px;
}
._coupon-signboard.add-img > .card-template.coupon + .card-template.coupon {
  margin-top: 0px;
}
._coupon-signboard.add-img > .card-template.coupon:nth-of-type(2n) {
  margin-left: 10px;
}
._coupon-signboard.add-img > .card-template.coupon:nth-of-type(n + 3) {
  margin-top: 10px !important;
}
.coupon-image-detail-wrap > .coupon-image-detail-label {
  color: #1c79bc;
  font-size: 15px;
}
.card-template.coupon-image-detail {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-top: 6px;
}
.card-template.coupon-image-detail > .main {
  padding: 15px;
}
.card-template.coupon-image-detail > .main > .permalink {
  font-size: 15px;
  font-weight: 500;
  color: #1c79bc;
}
.card-template.coupon-image-detail > .main > .label {
  font-size: 14px;
  color: #555;
  margin: 15px 0 5px;
  font-weight: bold;
}
.card-template.coupon-image-detail > .main > .description {
  overflow: hidden;
}
.card-template.coupon-image-detail > .main > .description > .option {
  margin-left: 5px;
  font-weight: bold;
  line-height: 30px;
}
.card-template.coupon-image-detail > .main > .description > .price {
  float: right;
  color: #7f7f7f;
  line-height: 30px;
  text-decoration: line-through;
}
.card-template.coupon-image-detail > .main > .description > .discounted {
  margin-left: 10px;
  float: right;
  text-align: right;
  color: #de2222;
  line-height: 30px;
  font-weight: bold;
}
.card-template.coupon-image-detail > .foot {
  padding: 0 15px 15px;
}
.card-template.coupon-image-detail > .foot > .info {
  padding-bottom: 10px;
  color: #7a7a7a;
}
.card-template.coupon-image-detail > .foot > .info > span {
  color: #1c79bc;
}
.card-template.coupon-image-detail > .foot > .btn-area {
  display: flex;
  grid-gap: 2%;
}
.card-template.coupon-image-detail > .foot > .btn-area > a {
  height: 35px;
  color: white;
  background-color: #1c79bc;
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.card-template.coupon-image-detail > .foot > .btn-area > a > button {
  width: 100%;
  height: 100%;
  color: white;
  background-color: #1c79bc;
  border: 1px solid #1c79bc;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-template.coupon-image-detail > .foot > .btn-area > a > button > img {
  display: block;
  width: 18px;
  height: 18px;
  margin-left: 2px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(63deg)
    brightness(104%) contrast(104%);
}
.card-template.coupon-image-detail > .foot > .btn-area .move-menu {
  color: #1c79bc;
  background-color: white;
}
.card-template.coupon-image-detail > .foot > .btn-area > ul {
  margin-left: 10px;
}
.card-template.coupon-image-detail > .foot > .btn-area > ul > li {
  margin-left: 10px;
  width: 50%;
  float: left;
}
.card-template.coupon-image-detail > .foot > .btn-area > ul > li:first-child {
  margin-left: -10px;
}
.card-template.coupon-image-detail > .foot > .btn-area > ul > li > .btn {
  width: 100%;
}
.coupon-image-detail-wrap:not(:first-of-type) {
  margin-top: 10px;
}

.coupon-result ._coupon-signboard .coupon .half-circle {
  background-color: #f8f8f8;
}

.coupon-result ._coupon-signboard .coupon .half-circle.left {
  border-right-color: #f8f8f8;
}
.coupon-result ._coupon-signboard .coupon .half-circle.right {
  border-left-color: #f8f8f8;
}
