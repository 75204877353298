.order-control {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.order-control > * {
  display: inline-block;
  min-width: 36px;
  border: 1px solid #dddddd;
  text-align: center;
  height: 30px;
  padding: 0;
  box-sizing: border-box;
  line-height: 28px;
}

.order-control > .btn {
  width: 26px;
  min-width: auto;
  font-style: normal;
  font-weight: bold;
}

.order-control > .btn.minus {
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.order-control > .btn.minus:after {
  content: '-';
}

.order-control > .btn.plus {
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.order-control > .btn.plus:after {
  content: '+';
}
.order-control > input {
  background: none;
  outline: none;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.order-control > input[type='number'] {
  -moz-appearance: textfield;
}

.order-control > input::-webkit-outer-spin-button,
.order-control > input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

section .price,
section .fluctuation {
  text-align: right;
}

section .fluctuation {
  color: #dddddd;
  flex: 0 0 80px !important;
}
section .fluctuation.up {
  color: rgb(222, 34, 34);
}
section .fluctuation.up:before {
  content: '\25B2';
  padding-right: 3px;
}

section .fluctuation.down {
  color: #1c79bc;
}
section .fluctuation.down:before {
  content: '\25BC';
  padding-right: 3px;
}
