.progress-view.loading-container {
  position: fixed;
  top: 0;
  width: 100%;
  margin: auto;
  z-index: 110;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 360px;
  max-width: 420px;
}
.progress-view.loading-container:active {
  pointer-events: none;
}

.progress-view.loading-container > .loading {
  padding: 20px 30px;
  opacity: 0.7;
  color: #ffffff;
  border-radius: 3px;
}
