.main .empty {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main .empty > p {
  text-align: center;
  color: #999999;
}

body .content > .main .section.card-list7._coupon-signboard {
  margin: 10px 0;
}

.my-page.categories {
  position: relative;
  max-width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
}
.my-page.categories > .group {
  position: fixed;
  height: 42px;
  width: 100%;
  max-width: 420px;
  z-index: 21;
}
.my-page.categories > .group .container {
  z-index: 0;
  background-color: #ffffff;
  border-color: #dddddd;
  border-style: solid;
  border-width: 0 0 1px 0;
}

.my-page.categories > .group .container .item {
  text-align: left;
  width: max-content;
}

.my-page.categories > .group .item {
  cursor: pointer;
  background-color: #ffffff;
  border-color: #dddddd;
  border-style: solid;
  border-width: 0 0 0 1px;
  padding: 10px 15px;
  height: 22px;
}

.my-page.categories > .group .item:first-child {
  border-width: 0;
}

.my-page.categories > .group .item * {
  color: #999999;
  font-weight: 400;
}

.my-page.categories > .group .item.on * {
  color: #1b79bc;
  font-weight: 500;
}

.my-page.categories .order-count-badge {
  position: relative;
  background-color: #de2222;
  width: 17px;
  height: 13px;
  border-radius: 6px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-right: 1px;
}
.my-page.categories .group .item .order-count-badge > div {
  font-size: 12px;
  color: white;
}

.my-page .section .phone-info {
  position: relative;
  border: none;
  width: 70%;
}
.my-page .section .phone-info input {
  position: relative;
  background-color: #eeeeee;
  border: none;
  border-radius: 3px;
  padding: 7px 10px;
  line-height: 20px;
  width: calc(100% - 20px);
}
.my-page .section .phone-info .hint {
  color: #999999;
  font-size: 12px;
}
.my-page .section .phone-info > .input-wrap-button {
  overflow: hidden;
}
.my-page .section .phone-info > .input-wrap-button input {
  float: left;
  width: calc(100% - 110px);
  box-sizing: border-box;
}
.my-page
  .section
  .phone-info
  > .input-wrap-button
  input[type='number']::-webkit-outer-spin-button,
.my-page
  .section
  .phone-info
  > .input-wrap-button
  input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.my-page .section .phone-info > .input-wrap-button button {
  display: flex;
  width: 100px;
  padding: 7px 0;
  margin-left: 10px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}
.my-page .section .phone-info > .input-wrap-button button > span {
  color: #fff;
}
.my-page .section .phone-info > .input-wrap-button > .timer-input {
  float: left;
  position: relative;
  width: calc(100% - 110px);
  overflow: hidden;
}
.my-page .section .phone-info > .input-wrap-button > .timer-input > input {
  width: 100%;
}
.my-page .section .phone-info > .input-wrap-button > .timer-input > span {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #de2222;
}

.my-page .inquire-btn-area {
  display: inline-flex;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 15px 10px 15px;
}
.my-page .inquire-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  height: 34px;
  border-radius: 6px;
  background-color: #1c79bc;
  margin: auto;
}

.main .foot .section.guide.type2 p {
  color: #999999;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.6px;
}
.main .foot .section.guide.type3 p {
  color: #999999;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.65px;
}

.order-panel {
  padding-bottom: 10px;
}
.order-panel .blue {
  color: #1c79bc;
}
.order-panel h3 {
  font-weight: 600;
}
.order-panel .order-panel-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border: 1px solid #dddddd;
  border-bottom: none;
  font-weight: 600;
}
.order-panel .order-panel-head .order-date {
  white-space: nowrap;
  display: inline;
  font-weight: 400;
  font-size: 15px;
}
.order-panel .order-panel-body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dddddd;
}
.order-panel .order-panel-body .order-name-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 15px;
}
.order-panel .order-panel-body .store-name {
  font-weight: 500;
  /* padding-bottom: 5px; */
}
.order-panel .order-panel-body .order-status {
  display: flex;
  position: relative;
}
.order-panel .order-panel-body .order-status .reservation-info {
  max-width: 230px;
  height: 20px;
  margin-left: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.order-panel .order-panel-body .order-name-price .order-name-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order-panel
  .order-panel-body
  .order-name-price
  .order-name-section
  .order-name {
  font-weight: 400;
}
.order-panel
  .order-panel-body
  .order-name-price
  .order-name-section
  .order-detail-button {
  padding: 0 5px;
  font-size: 12px;
  color: #999999;
  line-height: 18px;
}
.order-panel .order-panel-body .partition {
  width: 100%;
  height: 0px;
  border-top: 1px #dddddd solid;
}
.order-panel .order-panel-body .order-button-area {
  display: inline-flex;
  padding: 15px 0;
  gap: 12px;
  width: 100%;
}
.order-panel .order-panel-body .order-button-area a {
  width: 100%;
}
.order-panel .order-panel-body .order-button-area .order-button {
  height: 34px;
  width: 100%;
  background: #ffffff;
  border: 1px #9fa4aa solid;
  border-radius: 5px;
  color: #26282b;
}

.order-panel .order-panel-body .order-button-area .order-button-blue {
  height: 34px;
  width: 100%;
  background: #f2fbfe;
  border: 1px #2f80ed solid;
  border-radius: 5px;
  color: #2f80ed;
}

.order-panel .order-panel-body .order-button-area .order-button-inquiry {
  height: 34px;
  width: 100%;
  background: #ffffff;
  border: 1px #9fa4aa solid;
  border-radius: 5px;
  color: #26282b !important;
  cursor: pointer !important;
}
.order-panel .order-panel-body .order-button-area .order-button:disabled {
  color: #999999;
  background: #fff;
  border-color: #ccc;
  cursor: default;
}
.order-panel .order-panel-body .order-comment {
  font-size: 12px;
  color: #777777;
}
.example::-webkit-scrollbar {
}
.order-detail-page {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  position: fixed;
  top: 0;
  width: 100%;
  background: #f7f8f9;
  max-width: 420px;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
.order-detail-page .order-history-detail {
  display: block;
  overflow-y: auto;
  position: relative;
  width: 100%;
  padding-bottom: 56px;
}
.order-detail-page::-webkit-scrollbar {
  display: none;
}
.order-panel .order-panel-body ul {
  width: 100%;
}
.order-panel .order-panel-body ul li {
  display: flex;
  padding-bottom: 10px;
  justify-content: flex-start;
}
.order-panel .order-panel-body ul li:last-child {
  padding-bottom: 0;
}
.order-panel .order-panel-body ul li .li-title {
  width: 91px;
  min-width: 91px;
  display: block;
}
.order-panel .order-panel-body ul li .li-content {
  width: fit-content;
  color: #777777;
  word-break: break-word;
}
.order-panel .order-panel-body ul span {
  display: flex;
  padding-bottom: 10px;
  justify-content: space-between;
  width: 100%;
}
.order-panel .order-panel-body .order-item > * {
  /* padding: 5px 0; */
}
.order-panel .order-panel-body .order-item {
  width: 100%;
  padding: 5px 0;
  border-bottom: 1px #dddddd solid;
}
.order-panel .order-panel-body .order-item:first-child {
  padding-top: 0;
}
.order-panel .order-panel-body .order-item:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.order-panel .order-panel-body .order-item .order-description {
  color: #777777;
}
.order-panel .order-panel-body .order-item .order-qty-price {
  /* display: flex; */
  /* justify-content: space-between; */
}
.order-panel .order-panel-body .order-item .order-qty-price .order-qty {
  color: #777777;
}
