/*- header ----------------------------------------------------------------------*/
.header {
  position: relative;
  top: 0;
  z-index: 30;
  width: 100%;
  height: 90px;
  background-color: #fff;
}
.header .ci {
  position: absolute;
  top: 0;
  left: 0;
}
.header .ci .img-menu {
  margin: 7px 10px;
  width: 162px;
  height: 36px;
  background-image: url('./img/ci.png');
  background-size: 162px 36px;
  background-repeat: no-repeat;
}
.header .gnb {
  position: absolute;
  top: 16px;
  right: 15px;
}
.header .gnb * {
  color: #999999;
  font-size: 13px;
  letter-spacing: -0.65px;
  line-height: 19px;
}
.header .lnb {
  position: relative;
  max-width: 100%;
  top: 50px;
  height: 40px;
}
.header .lnb * {
  color: #777777;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.7px;
  font-weight: 500;
}
.header .lnb > ul {
  display: table;
  width: 100%;
  max-width: 100%;
  height: 20px;
  padding: 10px 0 10px 0;
  margin-left: -7px;
}
.header .lnb > ul > li {
  display: table-cell;
  text-align: center;
  height: 16px;
}
.header .lnb > ul > li:first-child {
  padding-left: 15px;
}
.header .lnb > ul > li.on > * {
  color: #1c79bc;
}
.header .lnb > ul > li > a {
  display: block;
}

.header .order-count-badge {
  position: absolute;
  left: -10px;
  top: 15px;
  background-color: #de2222;
  width: 17px;
  height: 13px;
  border-radius: 6px;
  transform: translate(-10px, -10px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-right: 1px;
}
.header .order-count-badge > div {
  font-size: 12px;
  color: white;
}

.content-information
  > .inner-section.type1
  > .head-line-area
  > .back
  > .img-bul.close {
  position: relative;
  box-sizing: border-box;
  padding: 7px;
  width: calc(15px + 14px);
  height: calc(15px + 14px);
  background: none;
  /* background-size: 15px;
  background-image: url(./img/bul_close.svg);
  background-repeat: no-repeat;
  background-position: 50%; */
}
.content-information
  > .inner-section.type1
  > .head-line-area
  > .back
  > .img-bul.close
  > img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  transform: translate(-50%, -50%);
}
