.search-filter {
  position: relative;
  max-width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
}

.search-filter {
  z-index: 10;
  position: relative;
}
.search-filter.my-page {
  z-index: 1;
}

.search-filter .filter-area > .filter:first-child {
  border-left-width: 0;
  width: 34%;
}

.search-filter .filter-area > .filter > dt {
  position: relative;
  text-align: center;
  padding: 10px 15px;
}

.search-filter .filter-area > .filter > dt * {
  color: #1c79bc;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.7px;
  font-weight: 500;
}

.search-filter .filter-area > .filter.off > dt {
  background-color: #eeeeee;
}

.search-filter .filter-area > .filter.off > dt * {
  color: #777777;
  font-weight: 400;
}

.search-filter.type2 > .filter-area > .filter {
  width: 50%;
}

.search-filter.type2 {
  overflow: hidden;
}

.search-filter.type2 > ul {
  white-space: nowrap;
  border-bottom: #dddddd 1px solid;
}

.search-filter.type2 > ul > li {
  display: inline-block;
  white-space: normal;
  background-color: #fff;
  height: 20px;
  border-color: #dddddd;
  border-style: solid;
  border-width: 0 0 0 1px;
  text-align: center;
  padding: 10px 15px;
}

.search-filter.type2 > ul > li * {
  color: #999999;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.7px;
}

.search-filter > .wrap-search-text-suggest {
  position: relative;
  overflow-y: auto;
  box-sizing: border-box;
  min-width: 360px;
  max-width: 420px;
  min-height: 400px;
  width: 100%;
  background-color: #ffffff;
  padding: 0 15px 15px;
}

.search-filter > .wrap-search-text-suggest > dl {
}

.search-filter > .wrap-search-text-suggest > dl > dt {
  clear: both;
  color: #555555;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.7px;
  font-weight: 500;
  padding-top: 10px;
}

.search-filter > .wrap-search-text-suggest > dl > dt > .remove-recent-search {
  margin-left: 5px;
  color: #999999;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.665px;
}

.search-filter > .wrap-search-text-suggest > dl > dd {
  margin-top: 5px;
}

.search-filter > .wrap-search-text-suggest > dl > dd > ul > li {
  float: left;
  margin: 5px 5px 0 0;
  border: #dddddd 1px solid;
  color: #777777;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.7px;
  border-radius: 3px;
  padding: 7px 15px;
}

.search-filter .filter-area > .filter > .item-group {
  overflow: hidden;
}

.search-filter .filter-area > .filter > .item-group * {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.7px;
}

.search-filter .filter-area > .filter > .item-group.type1 > ul {
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  max-height: 100%;
  width: 100%;
}

.search-filter .filter-area > .filter > .item-group.type1 > ul > li.on {
  background-color: #ffffff;
  color: #555555;
}

.search-filter .filter-area > .filter > .item-group.type1 > ul > li > ul {
  /*z-index:-1;*/
  /*position:fixed;*/
  z-index: 3;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 40px;
  padding-left: 140px;
}

.search-filter .filter-area > .filter > .item-group.type2 {
  z-index: 1;
  position: fixed;
  overflow: visible;
  box-sizing: border-box;
  max-height: 100%;
  min-width: 360px;
  max-width: 420px;
  width: 100%;
}

.search-filter .filter-area > .filter > .item-group.type2 > table {
  margin-left: calc(-34% - 1px);
  background: #ffffff;
  border-top: #dddddd 1px solid;
}

.search-filter .filter-area > .filter > .item-group.type2 > table td {
  position: relative;
  border: #dddddd 1px solid;
  border-width: 0 0 1px 1px;
  width: 33%;
  height: 85px;
}

.search-filter
  .filter-area
  > .filter
  > .item-group.type2
  > table
  td:first-child {
  width: 34%;
  border-left-width: 0;
}

.search-filter .filter-area > .filter > .item-group.type2 > table td > span {
  position: absolute;
  display: block;
  width: 100%;
  bottom: 10px;
  text-align: center;
}

.search-filter .filter-area > .filter > .item-group.type2 > table td.on > span {
  color: #1c79bc;
}

.search-filter .filter-area > .filter > .item-group.type2 > table td.all {
  background-image: url(./img/bul_filter_all.png);
  background-size: 86px;
  background-position: 50% 10px;
  background-repeat: no-repeat;
}

.search-filter .filter-area > .filter > .item-group.type2 > table td.more {
  background-image: url(./img/bul_filter_more.png);
  background-size: 86px;
  background-position: 50% 10px;
  background-repeat: no-repeat;
}

/*=====================================================*/
/*                     Text Filter                     */
/*=====================================================*/
.search-filter > .search-text {
  position: relative;
  max-width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 8px 10px;
}

.search-filter > .search-text > div {
  display: flex;
  box-sizing: border-box;
  border: 0;
  background-color: #eeeeee;
  border-radius: 3px;
}

.search-filter > .search-text > div > .text-field {
  background: none;
  height: 34px;
  width: 100%;
  border: none;
}

.search-filter > .search-text > div > .text-field::placeholder {
  color: #999999;
}

.search-filter > .search-text > div > .btn {
  width: 50px;
  background: transparent;
  color: #999999 !important;
  padding: 7px 10px;
  flex: 1 0 auto;
}

/*===============================================*/
/*                 Option Filter                 */
/*===============================================*/
.search-filter .filter-area {
  position: relative;
  width: 100%;
  border-top: 1px solid #c9cdd2;
  border-bottom: 1px solid #c9cdd2;
}
.search-filter .option-filter-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.search-filter .filter-area > .option-tabs {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
}

.search-filter .filter-area > .option-tabs > .option > span {
  color: #1c79bc;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.7px;
  font-weight: 500;
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}

.search-filter .filter-area > .option-tabs > .option.off,
.search-filter .filter-area > .option-tabs > .option.off > span {
  color: #777777;
  background-color: #eeeeee;
}

.search-filter .filter-area > .option-tabs > .filter {
  background-color: #fff;
  display: table-cell;
  height: 19px;
  border-left: 1px solid #c9cdd2;
}
.search-filter .filter-area > .option-tabs > .filter:first-child {
  border-left: none;
}

.search-filter .filter-area > .option-tabs > .filter.option {
  cursor: pointer;
  text-align: center;
  padding: 11px 0px;
  flex: 1 1 33%;
}

.search-filter .filter-area > .option-section {
  height: calc(100% - 171px);
  overflow: hidden;
  box-sizing: border-box;
  min-width: 360px;
  max-width: 420px;
  min-height: 380px;
  width: 100%;
  background-color: #ffffff;
  z-index: 5;
}

/*=============================================================*/
/*                 Option Filter for locations                 /
/*============================================================*/
.search-filter .filter-area > .option-section .list-options {
  overflow-y: auto;
}

.search-filter .filter-area > .option-section .dual-step-category {
  display: flex;
  background-color: #eeeeee;
  height: 100%;
}

.search-filter .filter-area > .option-section .dual-step-category li {
  cursor: pointer;
}

.search-filter .filter-area > .option-section .dual-step-category > .master {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex: 1 1 auto;
}

.search-filter
  .filter-area
  > .option-section
  .dual-step-category
  > .master::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.search-filter
  .filter-area
  > .option-section
  .dual-step-category
  > .master
  > li {
  box-sizing: border-box;
  background-color: #eeeeee;
  border-color: #dddddd;
  border-width: 0 0 1px 0;
  border-style: solid;
  color: #777777;
  text-align: center;
  padding: 10px 0;
  width: 100%;
}

.search-filter
  .filter-area
  > .option-section
  .dual-step-category
  > .master
  > li.on {
  background-color: #ffffff;
  color: #555555;
}

.search-filter .filter-area > .option-section .dual-step-category > .slave {
  overflow-y: auto;
  background-color: #ffffff;
  border-left: #dddddd solid 1px;
  flex: 0 0 calc(66% + 1px);
}

.search-filter
  .filter-area
  > .option-section
  .dual-step-category
  > .slave
  > li {
  box-sizing: border-box;
  background-color: #ffffff;
  border-color: #dddddd;
  border-width: 0 0 1px 1px;
  border-style: solid;
  color: #555555;
  text-align: left;
  padding: 10px;
  width: 100%;
  border-left: none;
}

.search-filter
  .filter-area
  > .option-section
  .dual-step-category
  > .slave
  > li.on {
  font-weight: 500;
}

/*===================================================================*/
/*                      Option Filter for Items                      */
/*===================================================================*/
.search-filter .filter-area .option-section .grid-category {
  background-color: #eeeeee;
  height: 100%;
}

.search-filter .filter-area .option-section .grid-category > ul {
  display: flex;
  flex-wrap: wrap;
  max-width: 420px;
  background-color: #ffffff;
}

.search-filter .filter-area .option-section .grid-category > ul > li.item {
  background-color: #ffffff;
  border-color: #dddddddd;
  border-width: 0 1px 1px 0;
  border-style: solid;
  flex: 1 1 33%;
  height: 85px;
  cursor: pointer;
  background-size: 80px;
  background-position: 50% 8px;
  background-repeat: no-repeat;
  position: relative;
}

.search-filter .filter-area .option-section .grid-category > ul > li.item.on * {
  color: #1b79bc;
  font-weight: 500;
}

.search-filter
  .filter-area
  .option-section
  .grid-category
  > ul
  > li.item:nth-child(3n + 1) {
  flex: 1 1 calc(33% + 1px);
}
.search-filter
  .filter-area
  .option-section
  .grid-category
  > ul
  > li.item:nth-child(3n + 3) {
  border-right: none;
}
.search-filter
  .filter-area
  .option-section
  .grid-category
  > ul
  > li.item.no-select {
  cursor: default;
}

.search-filter
  .filter-area
  .option-section
  .grid-category
  > ul
  > li.item
  > span {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 9px;
}

.item.all,
.item.cd-0000000000 {
  background-image: url(./_option/img/bul_filter_all.png);
}

.item.recommend,
.item.cd-1111111111 {
  background-image: url(./_option/img/bul_filter_recommend.png);
}

.item.fish-susi,
.item.cd-0000000038 {
  background-image: url(./_option/img/01@2x.png);
}

.item.fish,
.item.cd-0000000040 {
  background-image: url(./_option/img/02@2x.png);
}

.item.shrimp,
.item.cd-0000000039 {
  background-image: url(./_option/img/03@2x.png);
}

.item.clam,
.item.cd-0000000041 {
  background-image: url(./_option/img/04@2x.png);
}

.item.octopus,
.item.cd-0000000043 {
  background-image: url(./_option/img/05@2x.png);
}

.item.sea,
.item.cd-0000000042 {
  background-image: url(./_option/img/06@2x.png);
}

.item.seaweed,
.item.cd-0000000047 {
  background-image: url(./_option/img/07@2x.png);
}

.item.dried,
.item.cd-0000000045 {
  background-image: url(./_option/img/08@2x.png);
}

.item.salted,
.item.cd-0000000046 {
  background-image: url(./_option/img/09@2x.png);
}

.item.meat,
.item.cd-0000000048 {
  background-image: url(./_option/img/10@2x.png);
}

.item.restaurant,
.item.cd-0000000066 {
  background-image: url(./_option/img/11@2x.png);
}

.item.others,
.item.cd-0000000065 {
  background-image: url(./_option/img/bul_filter_more.png);
}

/*=====================================================================*/
/*                       Option Filter for order                       */
/*=====================================================================*/
.search-filter .filter-area > .option-section .list-options {
  background-color: #eeeeee;
  height: 100%;
}

.search-filter .filter-area > .option-section .list-options > ul {
  background-color: #ffffff;
}

.search-filter .filter-area > .option-section .list-options > ul > li {
  padding: 10px 15px 0 15px;
  text-align: center;
  color: #777777;
  cursor: pointer;
}

.search-filter .filter-area > .option-section .list-options ul > li > span {
  color: #777777;
}

.search-filter .filter-area > .option-section .list-options > ul > li:after {
  content: ' ';
  box-sizing: border-box;
  border-color: #dddddd;
  border-style: solid;
  border-width: 0 0 1px 0;
  display: block;
  margin-top: 10px;
}

.search-filter
  .filter-area
  > .option-section
  .list-options
  > ul
  > li:last-child {
  box-sizing: border-box;
  border-color: #dddddd;
  border-style: solid;
  border-width: 0 0 1px 0;
}

.search-filter
  .filter-area
  > .option-section
  .list-options
  > ul
  > li:last-child:after {
  border: none;
}

.search-filter .filter-area > .option-section .list-options .on > span {
  font-weight: 500;
  color: #1b79bc;
}

.search-filter
  .filter-area
  > .option-section
  .list-options
  > ul
  > li.spread
  > span:after {
  content: '▾';
  margin-left: 5px;
}

.search-filter
  .filter-area
  > .option-section
  .list-options
  > ul.master
  > li
  > span {
  font-size: 15px !important;
}

.search-filter
  .filter-area
  > .option-section
  .list-options
  > ul.master
  > li
  > ul {
  margin-top: 5px;
}

.search-filter
  .filter-area
  > .option-section
  .list-options
  > ul.master
  > li
  > ul
  > li {
  padding: 5px;
}

/*========================================================================*/
/*                               Tag Filter                               */
/*========================================================================*/
.search-filter .tag-group {
  border-bottom: 1px solid #dddddd;
  margin-top: 8px;
  padding: 0 16px;
}

.search-filter .tag-group .tag-item {
  cursor: pointer;
  background-color: #ffffff;
  height: 20px;
  /* border-color: #dddddd;
  border-style: solid;
  border-width: 0 0 0 1px; */
  text-align: center;
  padding: 8px 11px;
}

.search-filter .tag-group .tag-item:first-child {
  border-width: 0;
}

.search-filter .tag-group .tag-item * {
  color: #72787F;
  font-weight: 400;
  bottom: 0px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.3px;
}

.search-filter .tag-group .tag-item.on {
  cursor: default;
  pointer-events: none;
  border-bottom: 2px solid #26282B;
  padding-bottom: 10px;
}

.search-filter .tag-group .tag-item.on * {
  color: #26282B;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.3px;
  width: 100%;
  height: 100%;

  padding-bottom: 8px;
}

.search-filter .tag-group .tag-container .tag-item {
  text-align: left;
  width: max-content;
}
