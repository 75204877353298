._wholesales-board.section-title {
  display: flex;
}
._wholesales-board.section-title > * {
  display: inline-block;
  margin: 10px 0 0 0;
  padding: 7px 15px;
}
._wholesales-board.section-title > h3 {
  flex: 1 0 auto;
}
._wholesales-board.section-title > .unit {
  color: #999999;
}

._wholesales-board ul {
  margin: 0;
}
._wholesales-board ul li {
  margin: 13px 15px;
  display: flex;
  cursor: pointer;
}
._wholesales-board ul li .item {
  flex: 1 0 auto;
}
._wholesales-board ul li .description {
  flex: 0 0 auto;
  width: auto !important;
}
._wholesales-board ul li .price {
  flex: 0 0 auto;
  min-width: 80px;
  text-align: right;
}

._wholesales-board li.spot-list {
  display: block;
  position: relative;
  background-color: #eeeeee;
  margin: 0;
  padding: 20px;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
._wholesales-board li.spot-list .indicator {
  position: absolute;
  top: -10px;
  left: 50%;
  border-bottom: 10px solid #dddddd;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  margin-left: -5px;
}
._wholesales-board li.spot-list .indicator::after {
  content: '';
  position: absolute;
  top: 2px;
  left: -10px;
  border-bottom: 9px solid #eeeeee;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
._wholesales-board li.spot-list a {
  display: flex;
  margin-bottom: 5px;
}
._wholesales-board li.spot-list a:last-child {
  display: flex;
  margin-bottom: 0;
}
._wholesales-board li.spot-list a > .spot-label {
  color: #1c79bc;
  flex: 0 0 auto;
  margin-right: 5px;
}
._wholesales-board li.spot-list a > .spot-zone {
  color: #999999;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._wholesales-board ul li:last-child.spot-list {
  margin-bottom: 20px;
}

.img-block.biz {
  margin-top: 50px;
}
