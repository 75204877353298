.container {
  margin: 0 !important;
}
#wrap > .container {
  box-sizing: border-box;
}
#wrap > .container + .footer-fixed > .footer-change-wrap {
  display: none;
}
#wrap > .container.with-footer {
  padding-bottom: 60px;
  /* overflow-x: hidden; */
}
#wrap > .container.with-footer + .footer-fixed > .footer-change-wrap {
  display: flex;
}

.container .content.fixed {
  top: 0 !important;
}
