@charset 'utf-8';
/*===========================================================================
	GLOBAL
============================================================================*/
body .content > .main {
}
/*===========================================================================
	PAGE
============================================================================*/
body .content > .main .section.share {
  background-color: #eeeeee;
  border-bottom: #dddddd 1px solid;
  padding: 10px 15px;
}
body .content > .main .section.share .btn {
  padding: 5px 10px;
  border: #ddd 1px solid;
  background-color: #ffffff;
  color: #1c79bc;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.65px;
  border-radius: 3px;
}
body .content > .main .section.share > .comment {
  background-image: url('./img/bul_comment.svg');
  background-repeat: no-repeat;
  background-position: 8px 60%;
  padding-left: 26px;
}
body .content > .main .section.share > .btn-area {
  float: right;
}
body .content > .main .section.share > .btn-area > .btn {
  display: inline-block;
}

/*===========================================================================
	CONTENTS
============================================================================*/
.content-information {
}
.content-information > * {
  z-index: 1;
}
.content-information > .inner-section.type1 {
  z-index: 21;
  position: fixed;
  top: 0;
  overflow: hidden;
  max-width: 420px;
  width: 100%;
  background-color: #fff;
  border-bottom: #dddddd 1px solid;
  height: 50px;
}
.content-information > .inner-section.type1 > .head-line-area {
  box-sizing: border-box;
  margin: 0;
  display: flex;
  align-items: center;
  height: 100%;
}
.content-information > .inner-section.type1 > .head-line-area > ul {
}
.content-information > .inner-section.type1 > .head-line-area > ul > li {
}
.content-information > .inner-section.type1 > .head-line-area > .back {
  padding-left: 7px;
}
.content-information
  > .inner-section.type1
  > .head-line-area
  > .back
  > .img-bul {
  padding: 7px 12px 7px 7px;
  width: 15px;
  height: 15px;
  background-size: 15px;
  background-image: url(./img/bul_back.svg);
  background-repeat: no-repeat;
  background-position: 50% 52%;
}
.content-information > .inner-section.type1 > .head-line-area > .name {
  color: #555555;
  font-size: 16px;
  letter-spacing: -0.8px;
  font-weight: 500;
  display: contents;
}
.content-information > .inner-section.type1 > .head-line-area > .view {
  position: absolute;
  top: 10px;
  right: 15px;
  padding-left: 20px;
  color: #999999;
  font-size: 13px;
  line-height: 29px;
  letter-spacing: -0.65px;
  background-image: url(./img/bul_view.svg);
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 20px;
  max-width: 60px;
}
.content-information > .inner-section.type2 {
  height: 51px;
}

.article > p {
  text-align: center;
}
