.upload-images > ul > li {
  position: relative;
  margin-top: 30px;
}

.upload-images > ul > li .btn.remove {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #333333c9;
  color: #ffffff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  text-align: center;
}

.upload-images > ul > li .btn.remove::after {
  content: '\2716';
  font-style: normal;
  font-size: 1em;
}

.upload-images > ul > li img {
  width: 100%;
}
