section.supplier-head {
  margin-top: 20px !important;
}
.section-title + section.supplier-head {
  margin-top: 0 !important;
}
section.supplier-head > .item {
  border-top: 1px solid #dddddd;
}
section.supplier-head > .item:last-child {
  border-bottom: none !important;
}
section.order-by-supplier {
  background-color: #ffffff;
}
section.order-by-supplier > .item {
  border: none !important;
}
section.order-by-supplier::after {
  content: '';
  border-top: 1px dotted #dddddd;
  display: block;
  margin: 0 10px;
}
section.order-by-supplier > .item:last-child {
  border: none !important;
}
.item > h3 {
  color: #1b79bc;
  border-bottom: 2px solid #1b79bc;
  padding-bottom: 4px;
  font-weight: 500;
}

section.order-by-supplier > .item .add-control {
}

section.order-by-supplier > .item .add-control > i {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-block;
  width: 20px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
}

section.order-by-supplier > .item .add-control > i.plus:after {
  content: '+';
}
section.order-by-supplier > .item .add-control > i.minus:after {
  content: '-';
}
section.order-by-supplier > .item .add-control > i.minus.disabled {
  border-color: #eeeeee;
  color: #eeeeee;
}
section.order-by-supplier > .item .add-control > span {
  display: inline-block;
  min-width: 50px;
  text-align: center;
  margin: 0 10px;
}

section.estimate-btn .item {
  display: flex;
  justify-content: center;
}
section.estimate-btn .item > span {
  display: flex;
  justify-content: center;
}
section.estimate-btn .item > span > button {
  width: 100%;
  background-color: #1c79bc;
  color: white;
  padding: 10px 5px;
}
