.btn-group {
  position: relative;
}
.btn-close {
  cursor: pointer;
  background-color: #ffffff;
  background-image: url('../img/icon_close_gray.svg');
  background-repeat: no-repeat;
  border: none;
  position: absolute;
  top: 13px;
  left: calc(100% - 2rem);
  padding: 0;
  width: 15px;
  height: 15px;
}
.btn-close > i {
  color: #dddddd;
  font-weight: 500;
  font-size: 1.5rem;
}

.btn-close > i::before {
  content: '\2716';
  font-style: normal;
}
