.display-block {
  display: block;
}

/*===========================================================================
	CONTENTS
============================================================================*/
.store-information {
}
/* .store-information > *{z-index:1;} */
.store-information > .inner-section.type1 {
  z-index: 4;
  position: fixed;
  top: 0;
  overflow: hidden;
  max-width: 420px;
  width: 100%;
  background-color: #fff;
  height: 56px;
  border-bottom: #dddddd 1px solid;
}
.store-information > .inner-section.type1 > .head-line-area {
  box-sizing: border-box;
  margin: 0;
  padding: 10px 80px 11px 40px;
}
.store-information > .inner-section.type1 > .head-line-area > ul {
}
.store-information > .inner-section.type1 > .head-line-area > ul > li {
}
.store-information > .inner-section.type1 > .head-line-area > .back {
  position: absolute;
  top: 10px;
  left: 8px;
  height: 10px;
  margin: 0;
  padding: 0;
}
.store-information > .inner-section.type1 > .head-line-area > .back > .img-bul {
  padding: 7px;
  width: 15px;
  height: 15px;
  background-size: 15px;
  background-image: url(./img/bul_back.svg);
  background-repeat: no-repeat;
  background-position: 50%;
}
.store-information > .inner-section.type1 > .head-line-area > .name {
  color: #555555;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: -0.8px;
  font-weight: 500;
}
.store-information > .inner-section.type1 > .head-line-area > .view {
  position: absolute;
  top: 10px;
  right: 15px;
  padding-left: 20px;
  color: #999999;
  font-size: 13px;
  line-height: 29px;
  letter-spacing: -0.65px;
  background-image: url(./img/bul_view.svg);
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 20px;
  max-width: 60px;
}
.store-information > .inner-section.type2 {
  position: relative;
  min-height: 50px;
  overflow: hidden;

  margin: 56px 0 0;
}
.store-information > .inner-section.type2 > .tag {
  height: 22px;
  position: absolute;
  bottom: 10px;
  margin: 0 40px 0 10px;
  overflow: hidden;
}
.store-information > .inner-section.type2 > .favorite {
  position: absolute;
  right: 15px;
  bottom: 10px;
}
.store-information > .inner-section.type3 {
  background-color: #fff;
  padding: 0 24px 24px 19px;
}
.store-information > .inner-section.type3 * {
  line-height: 24px;
  letter-spacing: -0.3px;
}
.store-information > .inner-section.type3 .btn-box {
  display: inline-block;
  background-color: #ffffff;
  color: #1c79bc;
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: 500;
}
.store-information > .inner-section.type3 > ul > li {
  display: flex;
  letter-spacing: -0.3px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 20px;
}
.store-information > .inner-section.type3 > ul > li + li {
  margin-top: 16px;
}
.store-information > .inner-section.type3 > ul > li.address > p {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #26282b;
  justify-content: space-between;
}
.store-information > .inner-section.type3 > ul > li.addressCopy {
  margin-left: 31px;
  margin-top: 8px;
  width: 75px;
  cursor: pointer;
}
.store-information > .inner-section.type3 > ul > li.addressCopy > span {
  color: #2f80ed;
  font-weight: 500;
  letter-spacing: -0.3px;
}
.store-information
  > .inner-section.type3
  > ul
  > li.bussiness-time
  > ul
  > li
  + li {
  margin-top: 5px;
}
.store-information > .inner-section.type3 > ul > li.contact {
  background-image: url(./img/bul_phone.svg);
}
.store-information > .inner-section.type3 > ul > li.contact > ul > li {
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  max-width: 100%;
  overflow: hidden;
}
.store-information > .inner-section.type3 > ul > li.contact > ul > li > a {
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.store-information > .inner-section.type3 > ul > li.contact > ul > li + li {
}
.store-information > .inner-section.type3 > ul > li.delivery {
  clear: both;
  background-image: url(./img/bul_delivery.svg);
}
.store-information > .inner-section.type3 > ul > li.appOrder {
  background-position: 0px 5px;
}
.store-information > .inner-section.type3 > ul > li.delivery > ul > li + li {
  margin-top: 5px;
}
.store-information > .inner-section.type3 > ul > li.delivery > ul > li {
  position: relative;
  padding-left: 100px;
}
.store-information > .inner-section.type3 > ul > li.delivery > ul > li > span {
  position: absolute;
  left: 0;
  top: 0;
}
.store-information > .inner-section.type3 > ul > li.delivery > ul > li > a {
  margin-top: -5px;
}
.store-information > .inner-section.type4 {
  background-color: #fff;
  padding: 0 16px 24px 16px;
}

.level4 > .inner-section.type1 > .inner-inner-section.type3 .summary > span {
  float: left;
  height: 20px;
  line-height: 20px;
}
.level4
  > .inner-section.type1
  > .inner-inner-section.type3
  .summary
  > .tag
  > ul
  > li {
  height: 20px;
  line-height: 20px;
  margin-left: 4px;
}

/* Tag styles */
.level2 .tag.description > ul > li,
.level3 .tag.description > ul > li,
.level4 .summary > .tag > ul > li {
  /* font-size: 0.9em !important; */
  padding: 0 5px 1px;
}

._price-item-group > ul > li._price-item {
  cursor: pointer;
  display: flex;
}

._price-item-group > ul > li._price-item > .item {
  flex: 0 0 auto;
}

._price-item-group > ul > li.head > .description,
._price-item-group > ul > li._price-item > .description {
  flex: 0 1 auto;
  padding-left: 3px;
}

._price-item-group > ul > li._price-item > .price {
  position: static !important;
  margin-left: auto;
  flex: 0 0 auto;
}

.main .btn-area.fixed {
  display: flex;
  width: 100%;
  height: 44px;
  position: fixed;
  bottom: 0;
  z-index: 2007;
  transition: bottom 0.2s ease-in-out;
  max-width: 420px;
}
.main .btn-area > a {
  width: 100%;
  height: 100%;
}
.main .btn-area.fixed {
  z-index: 1;
}
.main .btn-area button {
  background-color: #1c79bc;
  color: white;
  width: 100%;
  height: 100%;
}
.main .btn-area button > a {
  color: white;
}

.main .footer-area.fixed {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 2007;
  max-width: 420px;
  height: 100%;
}
.main .footer-area .order-background {
  height: 100%;
  background: #000000cc;
}
.main .footer-area .order-view {
  padding: 0;
  margin-bottom: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
}
.main .footer-area .scroll-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.main .footer-area .order-view .size-selector-title {
  display: flex;
  align-items: center;
  height: 40px;
  background-color: #f8f8f8;
  font-size: 15px;
  line-height: 22px;
  border-top: 1px #dddddd solid;
  border-bottom: 1px #dddddd solid;
}
.main .footer-area .order-view .size-selector-title span {
  padding: 9px 15px;
  font-size: 15px;
  line-height: 22px;
}
.main .footer-area .order-view button {
  background-color: #ffffff;
}
.main .inner-section.type5 {
  margin: 0;
  padding: 0 15px;
  background-color: #ffffff;
}
.main .inner-section.size-selector {
  border-bottom: #dddddd 1px solid;
  margin: 0;
  padding: 15px;
}
.main .inner-section > .subject {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main .inner-section > .control-section-item {
  border-bottom: #dddddd 1px solid;
  padding: 10px 0;
  overflow: hidden;
}
.main .inner-section > .control-section-item > .order-menu-thumbnail {
  width: 60px;
  height: 60px;
  float: left;
  margin-right: 10px;
  background-color: #eee;
}
.main .inner-section > .control-section-item > .selected-title {
  float: left;
  width: calc(100% - 70px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.main .inner-section > .control-section-item .control-panel {
  float: left;
  width: calc(100% - 70px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main
  .inner-section
  > .control-section-item
  .control-panel
  > .order-control
  > * {
  border-radius: 0;
}
.main .inner-section > .control-section-item .control-panel > .item-price {
  color: #1c79bc;
}
.main
  .inner-section
  > .control-section-item
  .control-panel
  input[type='number']::-webkit-outer-spin-button,
.main
  .inner-section
  > .control-section-item
  .control-panel
  input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.main .inner-section > .total-price-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.main .inner-section > .total-price-section > .total-price {
  color: #1c79bc;
}

.footer-btn-area {
  display: flex;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  max-width: 420px;
}
.footer-btn-area > a {
  width: 100%;
  height: 100%;
  height: 64px;
}
.footer-btn-area button {
  background-color: #1c79bc;
  color: white;
  width: 100%;
  height: 64px;
  padding-bottom: 20px;
  position: relative;
}
.footer-btn-area button > * {
  color: white;
  display: inline-block;
}
.footer-btn-area .footer-button.disabled {
  background-color: #dddddd;
}
.footer-btn-area button > a {
  color: white;
}
.footer-btn-area button > .order-count-badge {
  position: absolute;
  right: 10px;
  top: 40%;
  background-color: #de2222;
  width: 17px;
  height: 13px;
  border-radius: 6px;
  transform: translate(-10px, -10px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.footer-btn-area button > .order-count-badge > div {
  font-size: 12px;
  color: white;
}

.main .footer-area .footer-btn-area {
  position: static;
}

.main .footer-area .order-view.card-template.type8 .inner-section {
  margin: 0;
}
.main .footer-area .order-view.card-template.type8 .inner-section.type1 {
  margin: 0 15px 20px 15px;
}
.main
  .footer-area
  .order-view.card-template.type8
  .inner-section.size-selector
  ul
  li {
  padding-bottom: 8px;
}
.main
  .footer-area
  .order-view.card-template.type8
  .inner-section.size-selector
  ul
  li:last-child {
  padding-bottom: 0;
}
.main
  .footer-area
  .order-view.card-template.type8
  .inner-section.size-selector
  .price {
  text-align: left;
}
.main
  .footer-area
  .order-view.card-template.type8
  .inner-section.size-selector
  .add-icon {
  padding: auto;
  width: 26px;
  height: 26px;
  border: 1px #dddddd solid;
}
.main .footer-area .order-view.card-template.type8 .inner-section .subject {
  margin: 14px 15px 5px 15px;
}
.main
  .footer-area
  .order-view.card-template.type8
  .inner-section
  .control-section-item {
  padding: 10px 0;
}
.main
  .footer-area
  .order-view.card-template.type8
  .inner-section
  .control-section-item:last-child {
  padding-bottom: 0;
}
.main
  .footer-area
  .order-view.card-template.type8
  .inner-section
  .total-price-section {
  padding-bottom: 15px;
}

.popover {
  position: absolute;
  right: 13px;
  width: 175px;
  float: right;
}
.popover-content {
  background: #464c53;
  border: 1px #464c53 solid;
  border-radius: 28px;
  padding: 0 12px;
  width: 100%;
  height: 36px;
  box-shadow: 0px 1px 3px #00000029;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}
.popover-message {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3px;
}

.popover-triangle {
  right: 30px;
  top: 30px;
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: #464c53 0 0;
  border: 1px #464c53 solid;
  border-radius: 2px;
  border-top: none;
  border-left: none;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  box-shadow: 1.42px 1.42px 1.5px #00000012;
  -webkit-transform: rotate(45deg);
}

.popover-triangle-product-modal {
  right: 10px !important;
  top: 25px;
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 16px;
  background: #464c53 0 0;
  border: 1px #464c53 solid;
  border-radius: 2px;
  border-top: none;
  border-left: none;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 1px;
  box-shadow: 1.42px 1.42px 1.5px #00000012;
  -webkit-transform: rotate(45deg);
}
