.file-button {
  display: inline-block;
}
.file-button img {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.file-button label > input[type='file'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
}
