.progress-view.circle-spinner {
  margin: 10px auto;
  width: 70px;
  height: 70px;
  position: relative;
}
.progress-view.circle-spinner > div {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.progress-view.circle-spinner > div:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #4e4e4e;
  border-radius: 100%;
  -webkit-animation: circle-spinner 1.2s infinite ease-in-out both;
  animation: circle-spinner 1.2s infinite ease-in-out both;
}
.progress-view.circle-spinner > div:nth-child(1) {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.progress-view.circle-spinner > div:nth-child(2) {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.progress-view.circle-spinner > div:nth-child(3) {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.progress-view.circle-spinner > div:nth-child(4) {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.progress-view.circle-spinner > div:nth-child(5) {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.progress-view.circle-spinner > div:nth-child(6) {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-view.circle-spinner > div:nth-child(7) {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.progress-view.circle-spinner > div:nth-child(8) {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.progress-view.circle-spinner > div:nth-child(9) {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.progress-view.circle-spinner > div:nth-child(10) {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.progress-view.circle-spinner > div:nth-child(11) {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.progress-view.circle-spinner > div:nth-child(1):before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.progress-view.circle-spinner > div:nth-child(2):before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.progress-view.circle-spinner > div:nth-child(3):before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.progress-view.circle-spinner > div:nth-child(4):before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.progress-view.circle-spinner > div:nth-child(5):before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.progress-view.circle-spinner > div:nth-child(6):before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.progress-view.circle-spinner > div:nth-child(7):before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.progress-view.circle-spinner > div:nth-child(8):before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.progress-view.circle-spinner > div:nth-child(9):before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.progress-view.circle-spinner > div:nth-child(10):before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.progress-view.circle-spinner > div:nth-child(11):before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes circle-spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes circle-spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.progress-view.square-spinner {
  margin: 100px auto;
  width: 70px;
  height: 60px;
  text-align: center;
  font-size: 10px;
}

.progress-view.square-spinner > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin: 2px;

  -webkit-animation: square-spinner 1.2s infinite ease-in-out;
  animation: square-spinner 1.2s infinite ease-in-out;
}

.progress-view.square-spinner > div:nth-child(2) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.progress-view.square-spinner > div:nth-child(3) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.progress-view.square-spinner > div:nth-child(4) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.progress-view.square-spinner > div:nth-child(5) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes square-spinner {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes square-spinner {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
