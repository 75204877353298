.t-pirates-biz > section {
  padding: 0;
  margin: 0;
}
.t-pirates-biz > section > .item {
  background-color: #fff;
  padding: 12px 12px 0;
  display: flex;
}
.t-pirates-biz > section > .item:last-child {
  padding-bottom: 12px;
  border-bottom: 1px solid #dddddd;
}
.t-pirates-biz > section > .item > * {
  display: block;
  flex: 1 1 auto;
}
.t-pirates-biz > section > .item > label {
  flex: 0 0 auto;
  width: 100px;
}
.t-pirates-biz > section > .item > span > em {
  color: #999999;
}
.t-pirates-biz > section > .item .input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.t-pirates-biz > section > .item span.red {
  color: #d0011b !important;
}
.t-pirates-biz > section > .item .panel-input {
  position: relative;
  background-color: #eeeeee;
  border: none;
  border-radius: 3px;
  padding: 7px 10px;
  line-height: 20px;
}
.t-pirates-biz > section > .item .panel-input.red {
  border: 1px dashed #d0011b;
}
.t-pirates-biz > section > .item a.account-link {
  color: #7f7f7f;
  text-decoration-line: underline;
}
.t-pirates-biz > section.order {
  padding: 12px;
}
.t-pirates-biz > section.order > button {
  width: 100%;
}
.t-pirates-biz > section.order > ul {
  margin-top: 0;
  padding: 12px 0 12px 18px;
  list-style: disc;
}

.t-pirates-biz > section.not-flex > .item {
  display: block;
}
.t-pirates-biz > section.not-flex > .item::after {
  display: block;
  content: '';
  clear: both;
}
.t-pirates-biz > section.not-flex > .item > * {
  box-sizing: border-box;
}
.t-pirates-biz > section.not-flex > .item label {
  display: block;
  float: left;
}
.t-pirates-biz > section.not-flex > .item label + * {
  float: left;
  width: calc(100% - 100px);
}
