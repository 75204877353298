.display-block {
  display: block;
}

.carousel .card-template,
.carousel > ul.swiper-bul > li {
  cursor: pointer;
}

.carousel .card-template {
  margin: 0 !important;
  width: 100% !important;
}

.content > .main > .section-title2 + .section {
  border-top: 0 !important;
}
.content > .main > .section-title2 .badge {
  float: right;
  color: #72787f;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.3px;
  font-weight: 400;
}
.content > .main > .section-title2 .badge.today {
  float: right;
  color: #2f80ed;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.3px;
  font-weight: 400;
}
