.container {
  position: relative;
  top: 0;
  z-index: 20;
  margin: -90px 0 0;
  padding: 0;
  min-height: 100%;
  background-color: #fff;
}

.content {
  position: relative;
  top: 90px;
  left: 0;
  margin: 0;
  padding: 0;
  height: auto;
  background-color: #fff;
}

@keyframes myAnimation {
  0% {
    transform: translateY(50px);
  }
  40% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

/*- contents ----------------------------------------------------------------------*/
.container .content > .head {
}
body .container .content > .head .widget {
  position: relative;
  max-width: 100%;
  height: max-content;
  background-color: #f7f8f9;
  letter-spacing: -0.3px;
  line-height: 20px;
  border: 1px solid #e8ebed;
  overflow: hidden;
  border-radius: 5px;
  margin: 9px 16px 0 16px;
}
body .container .content > .head .open {
  position: relative;
  max-width: 100%;
  height: max-content;
  background-color: #f7f8f9;
  letter-spacing: -0.3px;
  line-height: 20px;
  border: 1px solid #e8ebed;
  overflow: hidden;
  border-radius: 5px;
  margin: 8px 16px;
}
.container .content > .head .widget * {
  color: #464c53;
  /* line-height: 48px; */
  font-weight: 400;
  text-align: left;
  font-size: 14px;
  letter-spacing: -0.75px;
  /* grid-column-start: 2; */
  /* white-space: nowrap; */
}
.container .content > .head .widget ul {
  /* display: grid;
  grid-template-columns: minmax(40px, 0fr) calc(100vw - 60px) 44px;
  grid-template-rows: 48px auto; */
}
.container .content > .head .widget ul.slide > li {
  animation-name: myAnimation;
  animation-duration: 5500ms;
  animation-delay: -500ms;
  animation-iteration-count: infinite;
}

.container .content > .head .arrow-area {
  grid-column-start: 3;
  grid-row-start: 1;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
.container .content > .head .dropdown-arrow {
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  background-position: 50%;
}

.container .content > .head .search-result {
  margin: 18px 35px 8px 15px;
  padding: 0;
  position: relative;
}
.container .content > .head .search-result > * {
  color: #555555;
  font-size: 14px;
  letter-spacing: -0.7px;
  line-height: 20px;
}
.container .content > .head .search-result > .hint {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  right: -18px;
  top: 0;
  background-color: #dddddd;
  color: #777777;
  line-height: 20px;
}
.container .content > .main {
}
.container .content > .foot {
}
.address-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.address-tab {
  margin-top: 15px;
  box-shadow: 0 -1px grey;

  width: 100%;
  height: 480px;

  overflow-y: auto;
}
.address-wrap > .address-tab > img {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
}

.foot > .copyright {
  padding: 4px 0px;
  font-weight: 600;
  color: #666 !important;
  cursor: pointer;
}
.foot > .foot-expand {
  background: #f3f3f3;
  padding: 8px 0px;
  border-radius: 4px;
}
.foot > .foot-expand > .foot-expand-line {
  text-align: left;
  display: flex;
  align-items: flex-start;
}
.foot > .foot-expand > .foot-expand-line > strong {
  display: inline-block;
  width: 100px;
  padding: 0 8px;
  box-sizing: border-box;
}
.foot > .foot-expand > .foot-expand-line > span {
  display: inline-block;
  width: calc(100% - 100px);
  font-size: 11px;
}
